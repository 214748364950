<template>
  <Card class="px-5 py-3 flex flex-col w-full mt-5">
    <template>
      <div
        class="flex justify-between items-center w-full gap-4 pb-2 cursor-pointer"
        @click="handleShowDetails"
      >
        <span
          class="font-bold text-base leading-5 uppercase text-darkPurple whitespace-nowrap"
        >
          Preliminary Details
        </span>
        <div class="w-full border-t border-solid border-romanSilver h-0" />
        <Icon
          class-name="text-darkPurple mx-auto self-center"
          size="xs"
          :icon-name="showDetails ? 'chevronDown' : 'chevronRight'"
        />
      </div>
    </template>
    <template>
      <div class="flex flex-col gap-3" :class="{ hidden: !showDetails }">
        <div class="flex border-b border-romanSilver pb-4">
          <div class="flex flex-col px-5 items-center">
            <img
              v-if="employeeData.avatar !== '' && employeeData.avatar !== null"
              class="mr-3 rounded box-size-80px"
              :src="employeeData.avatar"
              alt="profile"
            />
            <div
              style="height: 80px; width: 80px; border-radius: 5px"
              class="text-blueCrayola border text-center font-black text-lg pt-7 mr-2"
              v-else
            >
              {{ $getInitials(`${employeeData.name}`) }}
            </div>

            <div
              class="flex flex-col items-center justify-center gap-0 whitespace-nowrap"
            >
              <span
                class="text-xs leading-4 font-black uppercase text-darkPurple"
              >
                {{ employeeData.name }}
              </span>
              <span class="text-jet leading-4 font-semibold text-10px">
                ID: {{ employeeData.id }}
              </span>
              <span class="font-semibold leading-4 text-romanSilver text-10px">
                {{ employeeData.designation }}
              </span>
            </div>
          </div>
          <div class="flex items-center w-full gap-5">
            <div class="border-r border-romanSilver h-28 ml-2" />
            <div class="flex flex-col flex-3 w-full">
              <div class="flex mb-2 w-full gap-2">
                <Card class="flex flex-1 p-3 gap-2 items-start h-14">
                  <Icon
                    class-name="text-romanSilver"
                    size="xs"
                    icon-name="icon-suitcase"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-semibold text-10px leading-4 text-chartPurple uppercase"
                    >
                      Designation
                    </span>
                    <div
                      class="flex justify-start items-center whitespace-nowrap gap-1"
                    >
                      <span
                        class="text-xs leading-5 font-semibold uppercase text-jet"
                        >{{ employeeData.designation }}</span
                      >
                      <span
                        class="font-semibold leading-4 text-romanSilver text-9px"
                        v-if="employeeData.dateEmployed !== ''"
                      >
                        (Since
                        {{
                          $DATEFORMAT(
                            new Date(employeeData.dateEmployed),
                            "MMMM dd, yyyy"
                          )
                        }})
                      </span>
                      <span
                        class="font-semibold leading-4 text-romanSilver text-9px"
                        v-else
                      >
                       (Since ----)
                      </span>
                    </div>
                  </div>
                </Card>
                <Card class="flex flex-1 p-3 gap-2 items-start h-14">
                  <Icon
                    class-name="text-romanSilver"
                    size="xs"
                    icon-name="icon-department"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-bold text-xs tracking-wide text-carrotOrange uppercase"
                    >
                      Function
                    </span>
                    <span
                      class="text-xs leading-5 font-semibold uppercase text-jet"
                    >
                      {{ employeeData.department }}
                    </span>
                  </div>
                </Card>
                <Card class="flex flex-1 p-3 gap-2 items-start h-14">
                  <Icon
                    class-name="text-romanSilver"
                    size="xs"
                    icon-name="icon-department"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-bold text-xs tracking-wide text-blueCrayola uppercase"
                    >
                      Supervisory Function
                    </span>
                    <span
                      class="text-xs leading-5 font-semibold uppercase text-jet"
                    >
                      {{ employeeData.supervisoryFunction }}
                    </span>
                  </div>
                </Card>
              </div>
              <div class="flex w-full gap-2">
                <Card class="flex flex-1 p-2 gap-2 items-center w-3/12 h-14">
                  <img
                    v-if="
                      lineManager.avatar !== '' && lineManager.avatar !== null
                    "
                    class="mr-3 rounded box-size-36px"
                    :src="lineManager.avatar"
                    alt="profile"
                  />
                  <div
                    style="height: 36px; width: 36px; border-radius: 5px"
                    class="text-blueCrayola border text-center font-semibold text-sm pt-2 mr-2"
                    v-else
                  >
                    {{ $getInitials(`${lineManager.name}`) }}
                  </div>
                  <div class="flex flex-col">
                    <span
                      class="font-bold text-xs tracking-wide text-apps-optima uppercase"
                    >
                      Line Manager
                    </span>
                    <span
                      class="font-bold text-10px leading-4 tracking-wide text-jet uppercase"
                    >
                      {{ lineManager.name }}
                    </span>
                    <span class="font-bold text-9px text-romanSilver uppercase">
                      {{ lineManager.designation }}
                    </span>
                  </div>
                </Card>
                <Card class="flex flex-3 p-3 gap-2 items-center w-9/12 h-14">
                  <div
                    class="flex flex-col justify-start items-start whitespace-nowrap"
                  >
                    <span
                      class="font-black text-xs leading-5 uppercase text-darkPurple"
                    >
                      Performance Score
                    </span>
                    <div
                      class="text-flame flex items-center justify-start gap-1"
                    >
                      <span class="text-10px leading-5 uppercase font-semibold">
                        View Full History
                      </span>
                      <Icon
                        class-name="box-size-10px"
                        icon-name="icon-arrow-right"
                      />
                    </div>
                  </div>
                  <div
                    class="border-l border-romanSilver px-3 flex justify-evenly items-center gap-3"
                  >
                    <template>
                      <div
                        class="flex justify-start items-center gap-1"
                        v-for="(scores, i) in performance"
                        :key="`${scores}-${i}`"
                      >
                        <span
                          class="font-semibold text-sm leading-5 text-darkPurple"
                          >{{ scores.year }}:</span
                        >
                        <div
                          class="rounded-md flex justify-center items-center gap-1 w-14 h-6"
                          :class="{
                            'bg-red-100 text-desire':
                              scores.status === 'decreasing',
                            'bg-green-100 text-mediumSeaGreen':
                              scores.status === 'increasing',
                            'bg-gray-100 text-romanSilver':
                              scores.status === 'default',
                          }"
                        >
                          <Icon
                            class-name="text-mediumSeaGreen"
                            size="xs"
                            :icon-name="
                              scores.status === 'increasing'
                                ? 'icon-trending-up-green'
                                : scores.status === 'decreasing'
                                ? 'icon-trending-down'
                                : 'icon-empty'
                            "
                          />
                          <span class="text-xs uppercase leading-5">{{
                            scores.value
                          }}</span>
                        </div>
                      </div>
                    </template>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <template v-if="detailsIsEditable">
          <div class="flex flex-col mt-2 gap-5 w-full">
            <div class="flex justify-start items-center text-darkPurple">
              <span class="font-semibold text-base leading-5"
                >Have previous interviews been conducted?</span
              >
              <RadioButton
                space-between="mr-2 ml-5"
                :options="[
                  { name: 'Yes', radioName: 'prevInterview', value: true },
                  { name: 'No', radioName: 'prevInterview', value: false },
                ]"
                v-model="previousInterviews"
              />
            </div>
            <div
              class="relative flex flex-col justify-start items-start gap-2"
              v-if="previousInterviews === 'true' || previousInterviews"
            >
              <label class="date-label"> If Yes Specify Date </label>
              <div class="date-flex w-1/4">
                <datepicker
                  placeholder="--Select Day & Month--"
                  style="width: 100%; outline: none"
                  input-class="date-input"
                  format="dd/MMMM"
                  v-model="interviewDays"
                />
              </div>
            </div>
            <div class="grid grid-cols-3 gap-y-3 gap-x-6">
              <div class="flex flex-col justify-start items-start gap-2">
                <label class="date-label"> PIP Initiation Date </label>
                <div class="date-flex w-full">
                  <datepicker
                    placeholder="--Select Day & Month--"
                    style="width: 100%; outline: none"
                    input-class="date-input"
                    format="dd/MMMM"
                    v-model="pipInitiation"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="flex flex-col justify-start items-start gap-2">
                <label class="date-label"> PIP Start Date </label>
                <div class="date-flex w-full">
                  <datepicker
                    placeholder="--Select Day & Month--"
                    style="width: 100%; outline: none"
                    input-class="date-input"
                    format="dd/MMMM"
                    v-model="pipStartDate"
                  />
                </div>
              </div>
              <div class="flex flex-col justify-start items-start gap-2">
                <label class="date-label"> PIP End Date </label>
                <div class="date-flex w-full">
                  <datepicker
                    placeholder="--Select Day & Month--"
                    style="width: 100%; outline: none"
                    input-class="date-input"
                    format="dd/MMMM"
                    v-model="pipEndDate"
                  />
                </div>
              </div>
              <div
                class="flex flex-col justify-start items-start gap-2"
                v-for="(n, index) in reviewSessions"
                :key="index"
              >
                <label class="date-label">
                  Review Session {{ index + 1 }}
                </label>
                <div class="date-flex w-full">
                  <datepicker
                    placeholder="--Select Day & Month--"
                    style="width: 100%; outline: none"
                    input-class="date-input"
                    format="dd/MMMM"
                    v-model="reviewSessions[index].date"
                  />
                </div>
              </div>
            </div>
            <div v-if="instructions">
              <div class="flex flex-col justify-start items-start gap-2">
                <div class="flex justify-start items-start gap-2">
                  <h3 class="font-bold text-lg leading-5 text-carrotOrange">
                    Instructions
                  </h3>
                  <div class="text-carrotOrange mx-auto self-center">
                    <Icon
                      size="xs"
                      icon-name="icon-warning"
                      class="text-carrotOrange"
                    />
                  </div>
                </div>
                <p class="text-base leading-5 font-normal text-jet">
                  {{ instructions }}
                </p>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col gap-2">
            <div
              class="font-semibold text-base leading-6 flex justify-start items-center gap-3"
            >
              <span class="text-romanSilver">Previous Interviews:</span>
              <span v-if="previousInterviews" class="text-darkPurple">
                Yes,
                {{ $DATEFORMAT(new Date(interviewDays), "MMMM dd, yyyy") }}
              </span>
              <span v-else class="text-darkPurple">None</span>
            </div>
            <div
              class="font-semibold text-base leading-6 flex justify-start items-center gap-3"
            >
              <span class="text-romanSilver">PIP Initiation Date:</span>
              <span v-if="pipInitiationDate" class="text-darkPurple">
                {{ $DATEFORMAT(new Date(pipInitiationDate), "MMMM dd, yyyy") }}
              </span>
            </div>
            <div
              class="font-semibold text-base leading-6 flex justify-start items-center gap-3"
            >
              <span class="text-romanSilver">PIP Duration:</span>
              <span class="text-darkPurple">
                {{ $DATEFORMAT(new Date(pipDuration.from), "MMMM dd, yyyy") }}
                -
                {{ $DATEFORMAT(new Date(pipDuration.to), "MMMM dd, yyyy") }}
              </span>
            </div>
            <div
              class="font-semibold text-base leading-6 flex justify-start items-center gap-3"
            >
              <span class="text-romanSilver">Review Sessions:</span>
              <span
                v-for="(sessions, i) in reviewSessions"
                :key="`${sessions}-${i}`"
                class="text-darkPurple"
              >
                {{
                  $DATEFORMAT(
                    new Date(reviewSessions[i].date),
                    "MMMM dd, yyyy"
                  )
                }};
              </span>
            </div>
          </div>
        </template>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "@scelloo/cloudenly-ui/src/components/card";
import RadioButton from "@scelloo/cloudenly-ui/src/components/radio";
import Datepicker from "vuejs-datepicker";
import Icon from "@/components/Icon";

export default {
  name: "ESSPreliminaryDetails",
  components: {
    Card,
    Icon,
    RadioButton,
    Datepicker,
  },
  props: {
    employeeData: {
      type: Object,
      default: () => ({
        avatar: "male-user.png",
        name: "Employee Name",
        id: "OA-XX9909808",
        designation: "Designation",
        dateEmployed: "April 20, 2016",
        department: "Department",
        supervisoryFunction: "Supervisory Function",
      }),
    },
    lineManager: {
      type: Object,
      default: () => ({
        avatar: "male-user.png",
        name: "Line Manager Name",
        designation: "Designation",
      }),
    },
    performance: {
      type: Array,
      default: () => [],
    },
    previousInterviews: {
      type: Boolean,
      default: false,
    },
    interviewDays: {
      type: String,
      default: "",
    },
    pipInitiationDate: {
      type: String,
      default: "",
    },
    pipStartDate: {
      type: String,
      default: "",
    },
    pipEndDate: {
      type: String,
      default: "",
    },
    pipDuration: {
      type: Object,
      default: () => ({
        from: "April 10, 2021",
        to: "June 30, 2021",
      }),
    },
    reviewSessions: {
      type: Array,
      default: () => [],
    },
    detailsIsEditable: {
      type: Boolean,
      default: false,
    },
    instructions: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showDetails: false,
      showDatePicker: false,
      selectPrevInterview: "",
      prevDate: "",
      pipInitiation: "",
      pipStart: "",
      pipEnd: "",
      reviews: [],
      payload: {
        hasDonePreviousInterview: false,
        pipInitiationDate: "",
        previousInterviewDate: "",
        pipStartDate: "",
        pipEndDate: "",
        reviewSessionDate: [],
      },
    };
  },

  methods: {
    handleShowDetails() {
      this.showDetails = !this.showDetails;
    },

    formatDate(value) {
      if (value !== "") {
        const month = this.$DATEFORMAT(new Date(value), "MM");
        const day = this.$DATEFORMAT(new Date(value), "dd");
        const year = this.$DATEFORMAT(new Date(value), "yyyy");

        return `${year}-${month}-${day}`;
      }
      return "";
    },

    handleShowDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },

    setPIPReview() {
      this.payload.reviewSessionDate = [];

      this.reviewSessions.forEach((review) => {
        this.payload.reviewSessionDate.push({
          reviewSessionId: review.reviewSessionId,
          date: this.formatDate(review.date),
        });
      });

      this.payload.hasDonePreviousInterview = this.previousInterviews;
      this.payload.pipInitiationDate = this.formatDate(this.pipInitiation);
      this.payload.previousInterviewDate = this.formatDate(this.interviewDays);
      this.payload.pipStartDate = this.formatDate(this.pipStartDate);
      this.payload.pipEndDate = this.formatDate(this.pipEndDate);

      this.$emit("set", this.payload);
    },

    activePIP() {
      setInterval(() => {
        this.setPIPReview();
      }, 1000);
    },
  },

  mounted() {
    this.pipInitiation = new Date();
    this.activePIP();
    this.selectPrevInterview = this.previousInterviews;
  },
};
</script>

<style>
.text-10px {
  font-size: 10px;
}
.text-9px {
  font-size: 9px;
}
.box-size-80px {
  width: 80px;
  height: 80px;
}
.box-size-36px {
  width: 36px;
  height: 36px;
}
.box-size-10px {
  width: 10px;
  height: 10px;
}
.text-apps-optima {
  color: #1a32cc;
}
</style>
