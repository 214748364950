<template>
  <Card class="px-3 py-3 flex flex-col w-full gap-3">
    <template>
      <div class="flex justify-start items-center gap-2">
        <h1 class="font-bold text-lg leading-6 tracking-0015 text-flame" v-if="header">
          {{ header }}
        </h1>
        <h2 class="font-bold text-lg leading-6 tracking-0015 text-darkPurple" v-if="title">
          {{ title }}
        </h2>
      </div>
    </template>
    <template>
      <Table
        :headers="headers"
        :items="items"
        aria-label="PerformanceSections"
        :has-number="false"
      >
        <template v-slot:item="{ item }">
          <div v-if="item.name" class="flex justify-start items-start gap-2 whitespace-nowrap">
            <img
              v-if="item.data.photo !== '' && item.data.photo !== null"
              class="mr-3"
              :src="item.data.photo"
              alt="profile"
              style="height: 30px; width: 30px; border-radius: 5px"
            />
            <div
              style="height: 30px; width: 30px; border-radius: 5px"
              class="text-blueCrayola border text-center font-black text-xs pt-2 mr-2"
              v-else
            >
              {{ $getInitials(`${item.data.name}`) }}
            </div>
            <div class="flex flex-col whitespace-nowrap font-semibold text-xs">
              <span class="text-darkPurple capitalize">
                {{ item.data.name }}
              </span>
              <span class="text-blueCrayola uppercase">
                {{ item.data.position }}
              </span>
            </div>
          </div>
          <div v-if="item.signature">
            <div v-if="item.data.signature" class="h-14 rounded-md p-3 text-lg font-bold">
              {{item.data.signature}}
            </div>
            <div v-else class="flex">
              <span v-if="item.data.userId === $AuthUser.id" class="text-flame text-sm font-bold cursor-pointer"
              @click="signPIP(item.data.name, item)">
                SIGN
              </span>
              <span v-if="item.data.userId !== $AuthUser.id" class="text-darkPurple text-base font-normal text-center">
                {{ '---' }}
              </span>
            </div>
          </div>
          <div v-if="item.comments" class="font-normal text-base leading-5">
            <div>
              <span class="text-darkPurple" v-if="item.data.userId !== $AuthUser.id">
                {{ item.data.comments ? item.data.comments : '---' }}
              </span>
              <Textarea
                v-else
                placeholder="Enter comment"
                @input="comments($event,item)"
                v-model="item.data.comments"
                :height="58"
              />
            </div>
          </div>
          <div v-if="item.date" class="flex flex-col justify-start items-start">
            <span class="text-base leading-5 font-normal text-darkPurple whitespace-nowrap">
              {{ item.data.date ? $DATEFORMAT( new Date(item.data.date), "MMMM dd, yyyy") : '---' }}
            </span>
          </div>
          <div v-if="item.impact" class="font-normal text-base leading-5">
            <span class="text-darkPurple">
              {{ item.data.impact }}
            </span>
          </div>
        </template>
      </Table>
    </template>
  </Card>
</template>

<script>
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import Table from "@scelloo/cloudenly-ui/src/components/table";
  import Textarea from "@scelloo/cloudenly-ui/src/components/text-area";

  export default {
    name: "SignaturesComments",
    components: {
      Card,
      Table,
      Textarea
    },
    props: {
      items: {
        type: Array,
        required: true,
        default: (() => [])
      },
      header: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        showComments: true,
        headers: [
          { title: "Signatory", value: "name" },
          { title: "Signature", value: "signature" },
          { title: "Comment", value: "comments" },
          { title: "Date", value: "date" },
          { title: "", value: "id" },
        ],
      };
  },

    methods: {
    /* eslint-disable no-console */
      handleIsEditable(id){
        this.showComments = id
      },

      comments(value, item) {
        // eslint-disable-next-line no-param-reassign
        item.data.comments = value;

        const dateValue = new Date().toISOString();

        // eslint-disable-next-line no-param-reassign
        item.data.date = this.formatSecDate(dateValue);
      },

    formatSecDate(value) {
      const month = this.$DATEFORMAT(new Date(value), "MM");
      const day = this.$DATEFORMAT(new Date(value), "dd");
      const year = this.$DATEFORMAT(new Date(value), "yyyy");

      return `${year}-${month}-${day}`;
    },

      signPIP(name, itemSign) {
        if (itemSign.data.comments === "") {
          this.$toasted.error('Comment is required', { duration: 5000 });
        }
        else {
        const dateValue = new Date().toISOString();

        // eslint-disable-next-line no-param-reassign
        itemSign.data.signature = this.$getInitials(`${name}`);
        // eslint-disable-next-line no-param-reassign
        itemSign.data.date = this.formatSecDate(dateValue);

          this.setPayload();
        }
      },

    setPayload() {
      const payload = this.items;

      this.$emit('set', payload);
    }
  },

  mounted() {
  }
  };
</script>
<style lang="scss">
  .tracking-0015 {
    letter-spacing: 0.015em;
  }
  tbody {
    tr {
      background: white !important;
      border-bottom: 1px dashed #878E99 !important;
    }
    tr:nth-last-child(-n+2) {
      border-bottom: 0 !important;
    }
  }
</style>

